<template>
    <div class="bg-gray-100">
        <div class="flex justify-between align-items-center p-2">
            <button @click="clearDataField" class="bg-white text-xs rounded border shadow-sm p-1">{{ $t('clear') }}</button>
            <small class="text-xs">{{ $t('filter') }}</small>
            <button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">{{ $t('done') }}</button>
        </div>
        <div class="flex flex-col">
            <div>
                <div class="bg-white border-t border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="date"/>
                        <span class="ml-2 cursor-pointer">{{ $t('date') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'date'" class="p-2">
                    <div class="block">
                        <el-date-picker
                        size="mini"
                        v-model="dateObject.date"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="|"
                        v-bind:start-placeholder="$t('startDate')"
                        v-bind:end-placeholder="$t('endDate')">
                        </el-date-picker>
                    </div>
                </div>
                <div class="bg-white border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="logType"/>
                        <span class="ml-2 cursor-pointer">{{ $t('logType') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'logType'" class="p-2">
                    <div class="form-group">
                        <select v-model="logObject.type" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            <option selected disabled>---</option>
                            <option value='Error'>{{ $t('logError') }}</option>
                            <option value='Info'>{{ $t('logInfo') }}</option>
                        </select>
                    </div>
                </div>
                <div class="bg-white border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="user"/>
                        <span class="ml-2 cursor-pointer">{{ $t('users') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'user'" class="p-2">
                    <div class="form-group">
                        <select v-model="userObject.value" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            <option :value="user.user.id" v-for="user in newUser" :key="user.id">
                                <span v-if="user.first_name && user.last_name">{{ user.first_name + ' ' + user.last_name }}</span>
                                <span v-else-if="user.user !== null">{{ user.user.groups[0].name }}</span>
                                <span v-else>{{ $t('user') }}</span>
                            </option>
                        </select>
                        <!-- <input type="text" v-model="userObject.value" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'FilterComponent',
        props : {
            data : {
                type : String,
                required : false
            },
            users : {
                type : Array,
                require : false
            }
        },
        data () {
            return {
                filter : '',
                value1 : '',
                dateObject : {
                    range : '',
                    date : '',
                },
                logObject : {
                    type : '',
                },
                userObject : {
                    value : '',
                },
                newUser : [],
            }
        },
        watch : {
            filter : function (newVal, _) {
                this.filter = ''
                this.filter = newVal
            }
        },
        mounted () {
            this.users.forEach(element => {
                if (element.user) {
                    this.newUser.push(element)
                }
            })
        },
        methods : {
            filterDataNow () {
                if (this.filter === 'date') {
                    this.$emit('filterDate', this.dateObject)
                }

                if (this.filter === 'logType') {
                    this.$emit('filterLogsType', this.logObject)
                }

                if (this.filter === 'user') {
                    this.$emit('filterUser', this.userObject)
                }

                // this.clearDataField()
                this.$emit('hideAdvanceFilter')
            },

            clearDataField () {
                this.filter = ''
                Object.keys(this.dateObject).forEach(element => { this.dateObject[element] = '' })
                Object.keys(this.logObject).forEach(element => { this.logObject[element] = '' })
                Object.keys(this.userObject).forEach(element => { this.userObject[element] = '' })
                window.Bus.$emit('reload-log')
            }
        }
    }
</script>

<style lang="scss" scoped>
.el-date-editor--daterange.el-input__inner{
    width: unset !important;
}
</style>
