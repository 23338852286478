<template>
	<div class="app-main__inner">
		<div class="absolute inset-0" style="z-index:9" @click="showFilter = false" v-if="showFilter"></div>
		<div class="container2 mb-3">
			<div class="flex flex-col md:flex-row justify-between align-items-center mb-3">
				<div>
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900">{{ $t('appLogs') }}</span>
					</div>
				</div>
				<div @click="showFilter = !showFilter"
					 class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">
                    <span
						class="flex justify-between align-items-center space-x-4 bg-white cursor-pointer px-3 py-2 rounded-md">
                        <span><img src="./../../assets/images/icons/Frame.png" alt=""></span>
                        <span class="text-gray-500">{{ $t('filter') }}</span>
                    </span>
				</div>
			</div>
			<div class="flex justify-end">
				<div class="absolute w-60 bg-white z-50 rounded border shadow -mt-5" v-if="showFilter">
					<FilterLogs :users="GET_SHOP_USERS"
								@hideAdvanceFilter='showFilter = !showFilter'
								@filterDate='filterLogByDate'
								@filterLogsType='filterLogByType'
								@filterUser='filterLogByUser'/>
				</div>
			</div>

			<div class="row dm-sans-font">
				<div class="col-md-12 mt-3">
					<div class="table-responsive rounded-lg bg-white border">
						<table style="width: 100%;" v-loading='loading' id="exampledestroy"
							   class="table table-hover table-bordered">
							<thead>
							<tr class="bg-white rounded p-1 border-b-2 shadow-sm">
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('logInitiator')
										}}<span class="flex flex-col"><i @click="sortLogInitiatorAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortLogInitiatorDsc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('event')
										}}<span class="flex flex-col"><i @click="sortLogDescriptionAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortLogDescriptionDsc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{ $t('logStatus') }}<span
										class="flex flex-col"><i @click="sortLogTypeAsc"
																 class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortLogTypeDsc"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{ $t('date') }}<span
										class="flex flex-col"><i @click="sortLogDateAsc"
																 class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortLogDateDsc"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="logs in GET_APPLICATION_LOGS.data" :key="logs.id">
								<td><span v-if="logs.initiator">{{
										logs.initiator.first_name | capitalizeNames
									}} {{ logs.initiator.last_name | capitalizeNames }}</span>
								</td>
								<!-- <td>{{ logs.end_customer__customer_name }}</td> -->
								<td><span v-if="GET_LOCALE_LANG === 'en'">{{ logs.description }}</span>
									<span v-else-if="GET_LOCALE_LANG === 'se'">{{ logs.description_swe }}</span></td>
								<!-- <td>
									<span v-if="logs.company">{{ logs.company }}</span>
									<span v-else>{{ $t('notAvailable') }}</span>
								</td> -->
								<td>
									<span class="px-4 py-1 bg-blue-500 text-white rounded-sm uppercase text-xs"
										  v-if="logs.event_state === 'Info'">{{ logs.event_state }}</span>
									<span class="px-4 py-1 bg-red-500 text-white rounded-sm uppercase text-xs"
										  v-else-if="logs.event_state === 'Error'">{{ logs.event_state }}</span>
									<span v-else>{{ logs.event_state }}</span>
								</td>
								<td>{{ $services.moment(logs.date).format('HH:mm YYYY-MM-DD') }}</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block" v-if="GET_APPLICATION_LOGS.data"> <!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_APPLICATION_LOGS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import FilterLogs from '@/customComponent/FilterComponent'


export default {
	name: 'ApplicationLogs',
	components: {Paginate, FilterLogs},
	data() {
		return {
			loading: true,
			limit: 10,
			showFilter: false,
			filter: '',
			company_id: '',
		}
	},
	computed: {
		...mapGetters({
			GET_SHOP_USERS: 'customer/GET_SHOP_USERS',
			GET_APPLICATION_LOGS: 'customer/GET_APPLICATION_LOGS',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
		})
	},
	mounted() {
		this.getShopUsers()
		const num = window.localStorage.getItem('lognum')
		const {company_id} = this.$route.params
		this.company_id = company_id
		if (this.company_id !== 'invalid_company') {
			if (parseInt(num)) {
				this.limit = num
				this.getApplicationLogs(parseInt(num))
			} else {
				window.localStorage.setItem('lognum', this.limit)
				this.getApplicationLogs(this.limit)
			}
		}
		if (this.company_id === 'invalid_company') {
			this.$services.helpers.notification(this.$t('no_company_text'), 'error', this)
		}
		window.Bus.$on('reload-log', _ => {
			this.getApplicationLogs(this.limit)
		})
	},
	methods: {
		getShopUsers() {
			this.$store.dispatch('customer/getShopUsers', {data: ''})
		},
		changePageNumber(number) {
			this.$store.dispatch('customer/getApplicationLogs', {page: `?page=${number}&company_id=${this.company_id}&limit=${this.limit + this.filter}`})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getApplicationLogs(limit) {
			this.loading = true
			this.$store.dispatch('customer/getApplicationLogs', {page: ``})
				.then(_ => {
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		filterLogByDate(data) {
			this.loading = true
			this.filter = `&start_date=${data.date[0]}&end_date=${data.date[1]}`
			const payload = {
				query: `?start_date=${data.date[0]}&end_date=${data.date[1]}&limit=${this.limit}&company_id=${this.company_id}`
			}
			this.$store.dispatch('customer/getApplicationLogs', {page: payload.query})
				.then(_ => {
					this.loading = false
					//
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		filterLogByType(data) {
			this.loading = true
			this.filter = `&event_state=${data.type}`
			const payload = {
				query: `?event_state=${data.type}&limit=${this.limit}&company_id=${this.company_id}`
			}
			this.$store.dispatch('customer/getApplicationLogs', {page: payload.query})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		filterLogByUser(data) {
			this.loading = true
			this.filter = `&user_id=${data.value}`
			const payload = {
				query: `?user_id=${data.value}&limit=${this.limit}&company_id=${this.company_id}`
			}
			this.$store.dispatch('customer/getApplicationLogs', {page: payload.query})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		sortLogInitiatorAsc() {
			this.$store.commit('customer/SORT_LOG_INITIATOR_ASC')
		},
		sortLogInitiatorDsc() {
			this.$store.commit('customer/SORT_LOG_INITIATOR_DESC')
		},
		sortLogDescriptionAsc() {
			this.$store.commit('customer/SORT_LOG_DESCRIPTION_ASC')
		},
		sortLogDescriptionDsc() {
			this.$store.commit('customer/SORT_LOG_DESCRIPTION_DESC')
		},
		sortLogDateAsc() {
			this.$store.commit('customer/SORT_LOG_DATE_ASC')
		},
		sortLogDateDsc() {
			this.$store.commit('customer/SORT_LOG_DATE_DESC')
		},
		sortLogTypeAsc() {
			this.$store.commit('customer/SORT_LOG_TYPE_ASC')
		},
		sortLogTypeDsc() {
			this.$store.commit('customer/SORT_LOG_TYPE_DESC')
		},
		shopResultNumber(e) {
			this.limit = parseInt(e.target.value)
			this.getApplicationLogs(parseInt(e.target.value), this.status)
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.color-red {
	color: red;
}

.row-ui {
	border-bottom: 1px solid #ddd;
	margin-left: 0;
	padding: 4px 0;
	font-size: 15px;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}
</style>
